import React from 'react';
import styled from 'styled-components';
import { FixedWidthContainer } from '../Container';

interface Background {
  backgroundColor?: string;
}

const Background = styled('div')<Background>`
  background-color: ${(p) => p.backgroundColor};
  width: 100%;
  height: 100%;
`;

Background.defaultProps = {
  backgroundColor: '#222',
};

interface PageLayout {
  children: React.ReactNode;
  backgroundColor: string;
}

const PageLayoutContainer = styled('div')<PageLayout>`
  background-color: ${(p) => p.backgroundColor};
  margin: 0 auto;
  padding: 48px;
  width: 100%;
  height: 100vh;
`;

PageLayoutContainer.defaultProps = {
  backgroundColor: '#fff',
};

const PageLayout: React.FC<PageLayout> = ({ children, backgroundColor }: PageLayout) => (
  <Background>
    <FixedWidthContainer>
      <PageLayoutContainer backgroundColor={backgroundColor}>{children}</PageLayoutContainer>
    </FixedWidthContainer>
  </Background>
);

export default PageLayout;
