/* eslint-disable react/jsx-filename-extension */
// /* eslint-disable react/jsx-filename-extension */
// import * as React from 'react';

// // import NotFoundMDX from './404.mdx';

// const IndexPage = (props) => {
//   return (
//     <Site seo={seo} {...props}>
//       <h1>NOT FOUND</h1>
//       {/* <NotFoundMDX /> */}
//     </Site>
//   );
// };

// export default IndexPage;

import React from 'react';
import { Site } from '../components/Site';
import NotFoundMDX from '../mdx/pages/404.mdx';

const seo = {
  title: '404: Not found',
};

export default function NotFound(props) {
  return (
    <Site seo={seo} {...props}>
      <NotFoundMDX />
    </Site>
  );
}
